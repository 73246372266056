<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M22 9.66999C21.9368 9.48708 21.822 9.32642 21.6693 9.20749C21.5167 9.08857 21.3328 9.01649 21.14 8.99999L15.45 8.16999L12.9 2.99999C12.8181 2.83092 12.6902 2.68833 12.5311 2.58857C12.3719 2.4888 12.1878 2.43588 12 2.43588C11.8121 2.43588 11.6281 2.4888 11.4689 2.58857C11.3097 2.68833 11.1819 2.83092 11.1 2.99999L8.54998 8.15999L2.85998 8.99999C2.6749 9.0263 2.5009 9.10396 2.35773 9.22415C2.21455 9.34435 2.10794 9.50227 2.04998 9.67999C1.99692 9.85367 1.99216 10.0385 2.03621 10.2147C2.08025 10.3909 2.17144 10.5517 2.29998 10.68L6.42998 14.68L5.42998 20.36C5.3896 20.5484 5.40453 20.7445 5.47296 20.9246C5.54139 21.1048 5.66041 21.2613 5.81571 21.3754C5.971 21.4895 6.15595 21.5563 6.34831 21.5677C6.54066 21.5792 6.73224 21.5348 6.89998 21.44L12 18.77L17.1 21.44C17.2403 21.5192 17.3988 21.5605 17.56 21.56C17.7718 21.5607 17.9784 21.4942 18.15 21.37C18.3051 21.2589 18.4252 21.1056 18.4961 20.9284C18.567 20.7512 18.5857 20.5575 18.55 20.37L17.55 14.69L21.68 10.69C21.8244 10.5677 21.9311 10.4069 21.9877 10.2263C22.0444 10.0458 22.0486 9.85287 22 9.66999ZM15.85 13.67C15.7342 13.7824 15.6474 13.9211 15.5969 14.0744C15.5464 14.2276 15.5337 14.3908 15.56 14.55L16.28 18.75L12.52 16.75C12.3738 16.6777 12.213 16.6401 12.05 16.6401C11.8869 16.6401 11.7261 16.6777 11.58 16.75L7.81998 18.75L8.53998 14.55C8.56622 14.3908 8.55354 14.2276 8.50305 14.0744C8.45255 13.9211 8.36572 13.7824 8.24998 13.67L5.24998 10.67L9.45998 10.06C9.62198 10.0375 9.77598 9.97553 9.90848 9.87964C10.041 9.78376 10.1479 9.65683 10.22 9.50999L12 5.69999L13.88 9.51999C13.952 9.66683 14.059 9.79376 14.1915 9.88964C14.324 9.98553 14.478 10.0475 14.64 10.07L18.85 10.68L15.85 13.67Z" fill="#333333"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>