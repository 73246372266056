<template>

    <div class="main-container-main">
        <div class="main-container-left-side-loader">
            <Skeleton class="loader" borderRadius="16px" width="100%" height="60px"/> 

            <Skeleton class="loader" borderRadius="16px" width="100%" height="575px"/> 

        </div>
        <div class="main-container-right-side-loader">
            <Skeleton class="loader" borderRadius="16px" width="100%" height="670px"/> 
        </div>
    </div>

</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";
import Skeleton from 'primevue/skeleton';

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>

.main-container-main {
    display: flex;
    flex-direction: row;

    width: 100%;

    gap: 20px;
    .main-container-left-side-loader {
        display: flex;
        flex-direction: column;

        gap: 30px;
        width: 100%;
        border-radius: 20px;
            
    }  

    .main-container-right-side-loader {
        display: flex;

        height: 670px;
        width: 440px;
        border-radius: 20px;
            
    } 
};


</style>